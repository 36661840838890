const selectProps = {
    styles: {
      control: base => ({
        ...base,
        height: 30,
        minHeight: 30
      }),
      indicatorSeparator: base => ({
        ...base,
        height: 16,
        marginTop: 6
      }),
      dropdownIndicator : base => ({
        ...base,
        padding: 4,
        paddingTop: 0
      }),
      singleValue: base => ({
        ...base,
        padding: 3,
        borderRadius: 5,
        fontSize: 12,
        background: "#EB603C",
        color: 'white',
        display: 'flex',
        top: '40%'
      }),
    },
    isSearchable: false,
}

export default selectProps