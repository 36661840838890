import React, { useState, useEffect } from "react"
import { ResponsiveChoropleth } from '@nivo/geo'
import china from "../../data/maps/china.json"

const ChinaMap = ({data, category,color,range})=>{
    const [windowSize, setWindowSize] = useState(undefined)
    useEffect(() => {
        function handleResize() {
          setWindowSize(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    let map = china.features
    // add id to the map
    map.forEach((province)=>{
        province.id = province.properties.name
    })
    
    // add node name to the map
    data.forEach((node)=>{
        const provinceIndex = map.findIndex((province)=>{
            return province.properties.name === node.province;
        });
        map[provinceIndex].spot = node.name;
    });

    const vpnData = data.map((node)=>{
        return {
            "id": node.province,
            "value": node[category]
    }})
    const nivoReverse = ['#48BCB5','#7CD5C4','#A9E8DC','#EBCAAF','#F78B79','#DD7C63']
    const colors = [nivoReverse, "nivo"]
    return(
        <div className="map-china">
            <ResponsiveChoropleth
                data={vpnData}
                features={map}
                margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
                colors={colors[color]}
                domain={range}
                unknownColor={"#f5f5f5"}
                label="spot"
                projectionTranslation={[ windowSize >1200 ? -0.65: windowSize >992 ? -0.80: windowSize >576 ? -0.9 : -0.5-(576-windowSize)/360,
                    windowSize >992 ? 1.35: windowSize >576 ?1.3 : 1.2]}
                projectionScale={windowSize >992 ? 500 : windowSize >576 ? 400: 300}
                projectionRotation={[ 0, 0, 0 ]}
                enableGraticule={true}
                graticuleLineColor="#dddddd"
                borderWidth={0.5}
                borderColor="#152538"
                legends={[
                    {
                        anchor: 'bottom-left',
                        direction: 'column',
                        justify: true,
                        translateX: 0,
                        translateY: -10,
                        itemsSpacing: 0,
                        itemWidth: 72,
                        itemHeight: 16,
                        itemDirection: 'left-to-right',
                        itemTextColor: '#444444',
                        itemOpacity: 0.85,
                        symbolSize: 16,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000000',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    )
}

export default ChinaMap