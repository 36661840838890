import React, { useState } from "react"
import { useStaticQuery,graphql } from "gatsby"
// import loadable from '@loadable/component'
import selectProps from "./selectProps"
// const ChinaMap = loadable(() => import('../chart/chinaMap'));
// const Select = loadable(() => import('react-select'));
import ChinaMap from "../chart/chinaMap"
import Select from "react-select"

const China = ({vpn_default, vpn_options})=>{
  const data = useStaticQuery(graphql`
    query {
      allDataJson {
        edges {
          node {
            out {
              updated
              vpns {
                name
                label
                speed {
                  name
                  province
                  down
                  up
                  ping
                }
              }
            }
          }
        }
      }
    }
  `)

  const vpns = data.allDataJson.edges.filter((edge) => {return edge.node.out !== null})[0].node.out
  const time = vpns.updated
  const vpnList = vpns.vpns
  const vpnDefault = vpn_default === undefined ? "lightyear" : vpn_default
  const filtered = vpnList.filter((vpn)=> {return vpn_options === undefined || vpn_options.includes(vpn.name) })
  const options = filtered.map((vpn)=>{
    return ({
      "value": vpn.name,
      "label": vpn.label
    })
  })
  const categories = [{"value":"down","label":"下载"},{"value":"up","label":"上传"},{"value":"ping","label":"Ping"}]
  const [vpnChosen, setVPN] = useState(vpnDefault)
  const [category, setCategory] = useState("down")

  const toggleChange = (e)=>{
    setVPN(e.value)
  }
  const toggleCate = (e)=>{
    setCategory(e.value)
  }
  const vpnDefaultOption = options.filter((vpn)=>{return vpn.value === vpnDefault})[0]
  const currentVPN = vpnList.filter((vpn)=> {return vpn.name === vpnChosen})[0]
  let color,range,unit
  if(["down","up"].includes(category)){
    color = 1
    range = [0,60]
    unit = "Mbps"
  }
  if(["ping"].includes(category)){
    color = 0
    range = [0,180]
    unit = "ms(毫秒)"
  }
  // console.log(currentVPN)

  return (
    <div className="visual">
      <div className="visual-chart">
        <div className="chart-title">
          <h5>速度测试</h5>
          <div className="right-select" >
            <div className="select large">
              <label htmlFor="测速VPN" >选择测速VPN</label>
              <Select
                {...selectProps}
                className="drop-select"
                defaultValue={vpnDefaultOption}
                label="Single select"
                onChange={toggleChange}
                options={options}
                id="测速VPN"
              />
            </div>
            <div className="select">
              <label htmlFor="测速项目" >选择测速项目</label>
              <Select
                {...selectProps}
                className="drop-select options"
                defaultValue={categories[0]}
                onChange={toggleCate}
                options={categories}
                id="测速项目"
              />
            </div>
          </div>
        </div>
      <ChinaMap data={currentVPN.speed} category={category} color={color} range={range} />
      <div className="chart-bottom">
        <p className="unit" >单位：{unit}</p>
        <div className="comment">
          <p>节点选自日本、香港、新加坡、美国、韩国、台湾</p>
          <p>测试数据来自阿里云，腾讯云. 由<a href="https://speedtest.net">speedtest.net</a>测速</p>
          <p>连接后，选择最快节点的平均速度制图</p>
          <p>更新于:  <span class="badge badge-primary">{time}</span></p>
        </div>
        </div>
      </div>
    </div>
  )
}

export default China